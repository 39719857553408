import dayjs from 'dayjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';

import EDMService from 'src/services/edm';
import mainStore from 'src/stores/mainStore';

import PreviewTemplateFactory from './templates/factory';

export default class ResultPageViewModel {
  projectId = '';
  newsletterId = '';
  resultId = '';
  startDate = '';
  endDate = '';

  @observable edmViewModel = null;

  constructor() {
    makeObservable(this);
  }

  @action didMount = async (params) => {
    const {
      id,
      rid
    } = params;

    this.newsletterId = id;
    this.resultId = rid;

    await this.getNewsletterDetail();
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');

      const { template } = await EDMService.getEDMDetail(this.newsletterId);
      runInAction(() => {
        // > factory
        this.edmViewModel = PreviewTemplateFactory.createViewModel({ id: this.newsletterId, rid: this.resultId, type: template });
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };
}
