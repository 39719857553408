import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import withRouter from 'src/components/withRouter';
import ResultPageViewModel from './viewModel';

import ResultTemplateFactory from './templates/factory';

@observer
class ResultPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ResultPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params);
  }

  render() {
    if (this.viewModel.edmViewModel) {
      return ResultTemplateFactory.generateView(this.viewModel.edmViewModel);
    }
    return null;
  }
}

ResultPage.propTypes = {
  router: PropTypes.object
};

ResultPage.defaultProps = {
  router: {}
};

export default withRouter(ResultPage);
