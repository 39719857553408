import API from 'src/apis';
import TopicListModel from 'src/models/response/TopicList';

export default class ResultService {
  static async getResultDetail(id, rid) {
    const res = await API.result.getResultDetail(id, rid);

    return res.data.result;
  }

  static async updateResultDetail(id, rid, memo) {
    await API.result.updateResultDetail(id, rid, memo);
  }

  static async updateLatestActionTime(id, rid) {
    await API.result.updateLatestActionTime(id, rid);
  }

  static async getResultSummary(id, date) {
    const res = await API.result.getResultSummary(id, date);

    return res.data.result;
  }

  static async getResultTopics(id, data, params = {}) {
    const res = await API.result.getResultTopics(id, data, params);

    const { result, searchId } = res.data;
    const { post } = TopicListModel.fromRes(result);

    return { list: post, searchId };
  }

  static async getResultTopicsByPage(id, params) {
    const res = await API.result.getResultTopicsByPage(id, params);

    const { post } = TopicListModel.fromRes([res.data.result]);

    return { list: post };
  }

  static async updateTopicAttribute(id, data) {
    await API.result.updateTopicAttribute(id, data);
  }

  static async getVolumeChartData(id, date) {
    const res = await API.result.getVolumeChartData(id, date);

    return res.data.result;
  }

  static async getFeatureData(id, date) {
    const res = await API.result.getFeatureData(id, date);

    return res.data.result;
  }

  static async manualSendEmail(id, rid) {
    await API.result.manualSendEmail(id, rid);
  }

  static async getTopicsByToken(token) {
    const res = await API.result.getTopicsByToken(token);

    const { result, searchId } = res.data.result;
    const { post } = TopicListModel.fromRes(result);

    return { list: post, searchId };
  }

  static async getTopicsPagingByToken(token, params) {
    const res = await API.result.getTopicsPagingByToken(token, params);

    const { post } = TopicListModel.fromRes([res.data.result]);

    return { list: post };
  }

  static async refreshAutoSummary(id, rid) {
    const { data } = await API.result.refreshAutoSummary(id, rid);

    return data.result;
  }
}
