import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import constants from 'src/constants';
import { AppStateContext } from 'src/stores';

import CustomizeLayout from './CustomizeLayout';
import EDMListPage from './EDMListPage';
import NewsletterPage from './NewsletterPage';
import ResultPage from './ResultPage';
import PreviewPage from './PreviewPage';
import TopicPreviewPage from './TopicPreviewPage';
// > auth related.
import LoginPage from './LoginPage';
import LoginSuccessPage from './LoginSuccess';
// > email related page.
import ScreenshotPage from './ScreenshotPage';
import EmailTopicPreviewPage from './EmailTopicPreviewPage';

// > 20240814 added maintenance page.
import MaintenancePage from './MaintenancePage';


const { ROUTES } = constants.routes;

const router = createBrowserRouter([
  {
    path: ROUTES.EDMList,
    element: <CustomizeLayout />,
    children: [
      {
        index: true,
        element: <EDMListPage />
      },
      {
        path: ROUTES.Newsletter,
        element: (
          <NewsletterPage />
        )
      },
      {
        path: ROUTES.Result,
        element: (
          <ResultPage />
        )
      },
      {
        path: ROUTES.Preview,
        element: (
          <PreviewPage />
        )
      },
      {
        path: ROUTES.TopicPreview,
        element: (
          <TopicPreviewPage />
        )
      }
    ]
  },
  {
    path: ROUTES.Login,
    element: (
      <LoginPage />
    )
  },
  {
    path: ROUTES.LoginSuccess,
    element: (
      <LoginSuccessPage />
    )
  },
  {
    path: ROUTES.EmailTopicPreview,
    element: (
      <EmailTopicPreviewPage />
    )
  },
  {
    path: ROUTES.Screenshot,
    element: (
      <ScreenshotPage />
    )
  },
  {
    path: ROUTES.Error,
    element: (
      <div>
        error
      </div>
    )
  },
  {
    path: ROUTES.NotFound,
    element: (
      <div>
        not found
      </div>
    )
  },
  {
    path: ROUTES.Maintenance,
    element: <MaintenancePage />
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.EDMList} replace />
  }
], {
  basename: '/'
});


class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <RouterProvider router={router} />
    );
  }
}

export default PagesRoot;
