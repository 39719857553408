import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { message } from 'antd';

import i18n from 'src/i18n';
import { SOURCE_CATEGORY_TC } from 'src/constants/normal';
import { formatDayjsWithWeekday } from 'src/utils';

import EDMService from 'src/services/edm';
import ResultService from 'src/services/result';
import ProjectService from 'src/services/project';
import mainStore from 'src/stores/mainStore';

export default class TopicPreviewPageViewModel {
  projectId = null;
  newsletterId = null;
  @observable newsletterSubject = '主題電子報主旨';

  resultId = null;
  @observable startDate = '';
  @observable endDate = '';
  subjectId = null;
  @observable subjectName = '主題話題';
  @observable subjectHasEvaluation = false;
  @observable subjectNeedShowBrand = false;

  @observable topicList = [];
  searchId = null;
  @observable hasNextPage = false;

  @observable tagPoolMap = new Map();

  @computed get currentPage() {
    return Math.ceil(this.topicList.length / 20);
  }

  @computed get dateString() {
    if (dayjs(this.endDate).diff(this.startDate, 'day') > 1) {
      return `${formatDayjsWithWeekday(this.startDate)} - ${formatDayjsWithWeekday(this.endDate)}`;
    }
    return formatDayjsWithWeekday(this.startDate);
  }

  constructor() {
    makeObservable(this);
  }

  @action didMount = async ({ id, rid, sid }) => {
    if (this.newsletterId === id) {
      return;
    }

    this.newsletterId = id;
    this.resultId = rid;
    this.subjectId = sid;


    await Promise.all([
      this.getNewsletterDetail(),
      this.getResultDetail()
    ]);

    await Promise.all([
      this.getResultTopics()
    ]);
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');
      const { projectId, subject, tags } = await EDMService.getEDMDetail(this.newsletterId);
      const { topics } = await ResultService.getResultDetail(this.newsletterId, this.resultId);

      runInAction(() => {
        this.projectId = projectId;
        this.newsletterSubject = subject;
        this.tagPoolMap = new Map(tags.map((tag) => [tag.id, tag.value]));
      });
      const targetSubject = topics.find((s) => s.id === this.subjectId);
      const { name, hasEvaluation, subjectNeedShowBrand } = await this.updateSubjectContent(targetSubject);
      runInAction(() => {
        this.subjectName = name;
        this.subjectHasEvaluation = hasEvaluation;
        this.subjectNeedShowBrand = subjectNeedShowBrand;
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };

  @action getResultDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('resultDetail')) {
        return;
      }
      mainStore.setLoading('resultDetail');
      const { startDate, endDate } = await ResultService.getResultDetail(this.newsletterId, this.resultId);
      runInAction(() => {
        this.startDate = startDate;
        this.endDate = endDate;
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_result_detail_error'));
    } finally {
      mainStore.setLoadingComplete('resultDetail');
    }
  };

  @action updateSubjectContent = async (subjectItem) => {
    const {
      hasEvaluation,
      searchKeywordLevel1Id,
      searchKeywordLevel2Id,
      searchKeywordLevel3Id,
      searchTagParentId,
      searchTagId,
      category
    } = subjectItem;

    const sourceText = !category[0]
      ? ''
      : `-${SOURCE_CATEGORY_TC[category[0]]}`;


    let name = '';

    if (searchKeywordLevel1Id && searchKeywordLevel2Id) {
      const [brand, productLine] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel2Id]);
      name = `${brand.name}/${productLine.name}${sourceText}`;
    }

    if (searchKeywordLevel1Id && searchKeywordLevel3Id) {
      const [brand, product] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel3Id]);
      name = `${brand.name}/${product.name}${sourceText}`;
    }

    if (searchKeywordLevel1Id && !searchKeywordLevel2Id && !searchKeywordLevel3Id) {
      const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
      name = `${brand.name}${sourceText}`;
    }

    if (searchTagParentId) {
      const [tagParent, tag] = await ProjectService.getTagContentByIds(this.projectId, [searchTagParentId, searchTagId]);
      if (searchKeywordLevel1Id) {
        const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
        name = `${brand.name}/${tagParent.name}/${tag.name}${sourceText}`;
      } else {
        name = `${tagParent.name}/${tag.name}${sourceText}`;
      }
    }

    return {
      name,
      hasEvaluation,
      subjectNeedShowBrand: searchTagParentId && !searchKeywordLevel1Id
    };

  };

  @action getResultTopics = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }
      mainStore.setLoading('topics');
      const { list, searchId } = await ResultService.getResultTopics(
        this.newsletterId,
        {
          date: {
            gte: this.startDate,
            lte: this.endDate
          },
          subjectId: this.subjectId,
          excludeNotSend: true
        }
      );

      runInAction(() => {
        this.topicList = [...list];
        this.searchId = searchId;
        this.hasNextPage = list.length === 20;
      });
    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };

  @action getPagingTopics = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }
      mainStore.setLoading('topics');
      const { list } = await ResultService.getResultTopicsByPage(this.newsletterId, { page: this.currentPage + 1, searchId: this.searchId, size: 20 });

      runInAction(() => {
        this.topicList = [...this.topicList, ...list];
        this.hasNextPage = list.length === 20;
      });

    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };
}
