import React from 'react';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { decodeToken } from 'react-jwt';
import { message } from 'antd';
import dayjs from 'dayjs';

import mainStore from 'src/stores/mainStore';
import i18n from 'src/i18n';
import { formatDayjsWithWeekday } from 'src/utils';
import EDMService from 'src/services/edm';
import ResultService from 'src/services/result';

export default class EmailTopicPreviewPageViewModel {
  ref = React.createRef();
  newsletterId = null;
  token = null;
  @observable newsletterSubject = '主題電子報主旨';

  subjectId = null;
  @observable subjectName = '主題話題';
  @observable subjectHasEvaluation = false;

  @observable startDate = null;
  @observable endDate = null;

  @observable topicList = [];
  searchId = null;
  @observable hasNextPage = false;

  positionY = 0;
  @observable isShowHeader = true;

  @observable tagPool = [];

  @observable minWidth = 600;

  @computed get currentPage() {
    return Math.ceil(this.topicList.length / 20);
  }

  @computed get ratingTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'rating').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get topicTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'topic').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get suggestionTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'suggestion').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get departmentTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'department').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get dateString() {
    if (dayjs(this.endDate).diff(this.startDate, 'day') > 1) {
      return `${formatDayjsWithWeekday(this.startDate)} - ${formatDayjsWithWeekday(this.endDate)}`;
    }
    return formatDayjsWithWeekday(this.startDate);
  }

  constructor() {
    makeObservable(this);
  }

  @action didMount = async ({ token }) => {
    if (!token) {
      return;
    }
    this.updateContent(token);

    const targetElement = this.ref.current;
    if (targetElement) {
      targetElement.addEventListener('scroll', this.scrollHandler);
    }

    const content = document.querySelector('#app').getBoundingClientRect();
    this.minWidth = Math.max(content.width, content.height);

    await Promise.all([
      this.getNewsletterDetail(),
      this.getResultTopics()
    ]);
  };

  @action willUnmount = () => {
    const targetElement = this.ref.current;
    if (targetElement) {
      targetElement.removeEventListener('scroll', this.scrollHandler);
    }
  };

  @action scrollHandler = () => {
    const { y } = document.querySelector('.scrollContainer').getBoundingClientRect();

    if (Math.abs(y - this.positionY) > 20) {
      // > scrolling top
      if (this.positionY < y) {
        this.isShowHeader = true;
      }
      if (this.positionY > y) {
        this.isShowHeader = false;
      }
      this.positionY = y;
    }

  };

  @action updateContent = (token) => {
    const { subject, title, hasEvaluation, showBrand, startDate, endDate } = decodeToken(token);
    console.log('results', decodeToken(token));
    this.token = token;

    this.newsletterSubject = subject;
    this.subjectName = title;
    this.subjectHasEvaluation = hasEvaluation;
    this.subjectNeedShowBrand = showBrand;
    this.startDate = startDate;
    this.endDate = endDate;
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');
      const { tags } = await EDMService.getEDMDetailByToken(this.token);

      runInAction(() => {
        this.tagPool = tags.map((tag) => ({ value: tag.id, label: tag.value, type: tag.type }));
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };

  @action getResultTopics = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }
      mainStore.setLoading('topics');
      const { list, searchId } = await ResultService.getTopicsByToken(this.token);

      runInAction(() => {
        this.topicList = [...list];
        this.searchId = searchId;
        this.hasNextPage = list.length === 20;
      });
    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };

  @action getPagingTopics = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }
      mainStore.setLoading('topics');
      const { list } = await ResultService.getTopicsPagingByToken(this.token, { page: this.currentPage + 1, searchId: this.searchId, size: 20 });

      runInAction(() => {
        this.topicList = [...this.topicList, ...list];
        this.hasNextPage = list.length === 20;
      });

    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };

}
