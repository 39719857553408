import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Layout, Table, FloatButton } from 'antd';
import { Waypoint } from 'react-waypoint';

import { isNegativeText } from 'src/utils';

import withRouter from 'src/components/withRouter';

import { OPEN_EDIT_TOPIC_TABLE, OPEN_EDIT_TOPIC_KEYS, CLOSE_EDIT_TOPIC_TABLE, CLOSE_EDIT_TOPIC_KEYS, OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE, CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE } from 'src/constants/table';

import i18n from 'src/i18n';
import Logo from 'src/assets/logo.svg';
import AnnouncementIcon from 'src/assets/common/announcement.svg';

import EmailTopicPreviewPageViewModel from './viewModel';

import styles from './styles.module.scss';

const { Header, Content } = Layout;

@observer
class EmailTopicPreviewPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new EmailTopicPreviewPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params);
  }
  render() {
    return (
      <Layout
        className={clsx(styles.main)}
        ref={this.viewModel.ref}
        style={{ minWidth: this.viewModel.minWidth }}
      >
        <Header
          className={clsx(styles.header, !this.viewModel.isShowHeader && styles.hideHeader)}
        >
          <div className={styles.headerContent}>

            <div className={styles.system}>
              <img src={Logo} alt={i18n.t('system_title')} />
            </div>

          </div>
        </Header>
        <Content
          className={clsx(styles.content)}
        >
          <div
            className={clsx('scrollContainer', styles.pageContainer)}
          >
            <div className={styles.contentContainer}>
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  <img src={AnnouncementIcon} alt="" className={styles.icon} />
                  {`${this.viewModel.newsletterSubject} / ${i18n.t('preview_page_topics_title')}「${this.viewModel.subjectName}」`}
                </div>
                <div className={styles.dateText}>
                  {this.viewModel.dateString}
                </div>
              </div>
              <div className={styles.topicContainer}>
                <div className={styles.tableContainer}>
                  <Table
                    dataSource={this.viewModel.topicList}
                    className={clsx('previewTable')}
                    rowKey={(data) => data.id}
                    pagination={false}
                  >
                    {
                      this.viewModel.subjectHasEvaluation
                        ? (
                          <>
                            {
                              (this.viewModel.subjectNeedShowBrand ? OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE : OPEN_EDIT_TOPIC_TABLE).map((column) => {
                                const width = (() => {
                                  if ([CLOSE_EDIT_TOPIC_KEYS.Title].includes(column.key)) {
                                    return '15%';
                                  }
                                  if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                    return '5%';
                                  }
                                  return '10%';
                                })();
                                return (
                                  <Table.Column
                                    title={column.title}
                                    dataIndex={column.dataIndex}
                                    key={column.key}
                                    width={width}
                                    fixed={column.fixed}
                                    render={(text, record, index) => {
                                      if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                        return (
                                          <div className={styles.row}>
                                            {index + 1}
                                          </div>
                                        );
                                      }
                                      if (column.key === CLOSE_EDIT_TOPIC_KEYS.Title) {
                                        return (
                                          <>
                                            <a
                                              href={record.url}
                                              className={clsx(styles.titleDeco, styles.twoLineRow)}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {text}
                                            </a>
                                            {
                                              (index === this.viewModel.topicList.length - 1 && this.viewModel.hasNextPage)
                                              && (
                                                <Waypoint
                                                  onEnter={this.viewModel.getPagingTopics}
                                                />
                                              )

                                            }
                                          </>
                                        );
                                      }
                                      if (column.key === OPEN_EDIT_TOPIC_KEYS.EvalType) {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div className={clsx(styles.row, styles.twoLineRow, isNegativeText(this.viewModel.ratingTags[text]) && styles.negative)}>
                                                  {this.viewModel.ratingTags[text] ?? '無'}
                                                </div>
                                              );
                                            }}
                                          </Observer>

                                        );
                                      }
                                      if (column.key === OPEN_EDIT_TOPIC_KEYS.TopicAttr) {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div className={clsx(styles.row, styles.twoLineRow)}>
                                                  {this.viewModel.topicTags[text] ?? '無'}
                                                </div>
                                              );
                                            }}
                                          </Observer>

                                        );
                                      }
                                      if (column.key === OPEN_EDIT_TOPIC_KEYS.ReportAdvice) {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div className={clsx(styles.row, styles.twoLineRow)}>
                                                  {this.viewModel.suggestionTags[text] ?? '無'}
                                                </div>
                                              );
                                            }}
                                          </Observer>

                                        );
                                      }
                                      if (column.key === OPEN_EDIT_TOPIC_KEYS.DepartmentAttr) {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div className={clsx(styles.row, styles.twoLineRow)}>
                                                  {this.viewModel.departmentTags[text] ?? '無'}
                                                </div>
                                              );
                                            }}
                                          </Observer>

                                        );
                                      }
                                      return (
                                        <div className={clsx(styles.row, styles.twoLineRow)}>
                                          {text}
                                        </div>
                                      );
                                    }}
                                  />
                                );
                              })
                            }
                          </>
                        )
                        : (
                          <>
                            {
                              (this.viewModel.subjectNeedShowBrand ? CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE : CLOSE_EDIT_TOPIC_TABLE).map((column) => {
                                const width = (() => {
                                  if ([CLOSE_EDIT_TOPIC_KEYS.Title].includes(column.key)) {
                                    return '20%';
                                  }
                                  if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                    return '5%';
                                  }
                                  return '15%';
                                })();
                                return (
                                  <Table.Column
                                    title={column.title}
                                    dataIndex={column.dataIndex}
                                    key={column.key}
                                    width={width}
                                    fixed={column.fixed}
                                    render={(text, record, index) => {
                                      if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                        return (
                                          <div className={styles.row}>
                                            {index + 1}
                                          </div>
                                        );
                                      }
                                      if (column.key === CLOSE_EDIT_TOPIC_KEYS.Sentiment) {
                                        return (
                                          <div
                                            className={
                                              clsx(
                                                styles.dot,
                                                record.sentiment === 1 && styles.positive,
                                                record.sentiment === 0 && styles.neutral,
                                                record.sentiment === -1 && styles.negative
                                              )
                                            }
                                          />
                                        );
                                      }
                                      if (column.key === CLOSE_EDIT_TOPIC_KEYS.Title) {
                                        return (
                                          <>
                                            <a
                                              href={record.url}
                                              className={clsx(styles.titleDeco, styles.twoLineRow)}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {text}
                                            </a>
                                            {
                                              (index === this.viewModel.topicList.length - 1 && this.viewModel.hasNextPage)
                                              && (
                                                <Waypoint
                                                  onEnter={this.viewModel.getPagingTopics}
                                                />
                                              )

                                            }
                                          </>
                                        );
                                      }
                                      return (
                                        <div className={clsx(styles.twoLineRow)}>
                                          {text}
                                        </div>
                                      );
                                    }}
                                  />
                                );
                              })
                            }
                          </>
                        )
                    }
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <FloatButton.BackTop
            visibilityHeight={100}
          />
        </Content>
      </Layout>
    );
  }
}

EmailTopicPreviewPage.propTypes = {
  router: PropTypes.object
};

EmailTopicPreviewPage.defaultProps = {
  router: {}
};

export default withRouter(EmailTopicPreviewPage);
